import { graphql, Link, navigate } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";
import { WpPage_Legal } from "~/_generated/types";
import moment from "moment";
import {
  Heading,
  HeadingSizeOption,
} from "~/components/elements/typography/Heading";
import {
  Subheading,
  SubheadingIconOption,
} from "~/components/elements/typography/Subheading";
import { Icon, IconType } from "~/components/elements/Icon";
import useLegal from "~/hooks/useLegalPages";
import Seo from "gatsby-plugin-wpgraphql-seo";

export default function legalPage({
  data: {
    wpPage: { legal },
    wp,
    wpPage,
  },
}: {
  data: { wpPage: { legal: WpPage_Legal } };
}) {
  const { nodes } = useLegal();

  const handleNavigate = (e) => {
    navigate(e);
  };

  let lastUpdatedDate = legal?.content?.lastUpdated;
  lastUpdatedDate = moment(lastUpdatedDate, "DD/MM/YYYY").format(
    "Do MMMM YYYY"
  );

  return (
    <Layout wp={wp} hideFooterCta>
      <Seo post={wpPage} />
      <section className="pt-[90px] pb-[40px] md:pt-[195px] md:pb-[98px] bg-purple-2">
        <div className="container relative text-center ">
          <div className="max-w-[871px] space-y-8 text-center mx-auto">
            {legal?.subheading && (
              <div className="flex justify-center">
                <Subheading
                  text={legal?.subheading}
                  icon={SubheadingIconOption.Legal}
                />
              </div>
            )}
            <Heading
              Type="h1"
              size={HeadingSizeOption.h1}
              text={legal?.heading}
            />
          </div>
        </div>
      </section>

      <section className="my-12 lg:mt-[97px] lg:mb-[200px]">
        <div className="container ">
          <div className="max-w-[720px] mx-auto">
            <div className="flex items-center mt-[21px] opacity-[.35]">
              <div className="w-4 h-4 fw-icon ">
                <Icon type={IconType.Clock} />
              </div>
              <p className="ml-3.5">Last Updated: {lastUpdatedDate}</p>
            </div>
            <div
              className="mt-8 prose p-large"
              dangerouslySetInnerHTML={{ __html: legal?.content?.content }}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Legal($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
